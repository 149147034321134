<template>
  <b-container id="saml-configuration-new" fluid>
    <b-row>
      <b-col cols="12">
        <h3>New SAML Configuration</h3>
        <router-link :to="{ name: 'SamlCoreConfigurationList' }" class="d-flex justify-content-left align-items-center">
          <b-icon icon="arrow-left" aria-label="Back to configuration list"/>
          Back to List
        </router-link>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <b-form-group
          label="Configuration Name"
          description="Unique key to identify the Partner in Sword"
          label-for="configuration-name"
          invalid-feedback="Invalid name"
          :state="nameValidationState">
          <b-form-input
            id="configuration-name" type="text" placeholder="Enter Configuration Name"
            :state="nameValidationState" v-model="data.name"/>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Public Key"
          description="Key Pair: Public Key"
          label-for="keypair-public"
          invalid-feedback="Invalid public key"
          :state="publicKeyValidationState">
          <b-form-file
            id="keypair-public" drop-placeholder="Choose a file or drop it here..."
            :state="publicKeyValidationState" v-model="data.public_key"/>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Private Key"
          description="Key Pair: Private Key"
          label-for="keypair-private"
          invalid-feedback="Invalid private key"
          :state="privateKeyValidationState">
          <b-form-file
            id="keypair-private" drop-placeholder="Choose a file or drop it here..."
            :state="privateKeyValidationState" v-model="data.private_key"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4" class="d-flex justify-content-left align-items-center">
        <b-button variant="primary" @click="create" :disabled="creating || $v.$invalid">
          <b-spinner small v-if="creating"></b-spinner>
          <span v-else>Save</span>
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue';
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import { create } from '@/api/saml/core/configurations';

export default {
  name: 'SamlCoreConfigurationNew',
  data() {
    return {
      data: {
        name: '',
        public_key: '',
        private_key: '',
      },
      creating: false,
    };
  },
  validations() {
    return {
      data: {
        name: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(255),
        },
        public_key: {
          required,
        },
        private_key: {
          required,
        },
      },
    };
  },
  computed: {
    nameValidationState() {
      return !this.$v.data.name.$invalid;
    },
    publicKeyValidationState() {
      return !this.$v.data.public_key.$invalid;
    },
    privateKeyValidationState() {
      return !this.$v.data.private_key.$invalid;
    },
  },
  methods: {
    create() {
      this.creating = true;
      const data = new FormData();
      data.append('name', this.data.name);
      data.append('public_key', this.data.public_key);
      data.append('private_key', this.data.private_key);

      create(data)
        .then(uuid => {
          this.$router.push({ name: 'SamlCoreConfigurationView', params: { uuid } });
        })
        .catch(err => {
          Vue.prototype.$noty.error(`Failed to create configuration: ${err}`);
        })
        .finally(() => {
          this.creating = false;
        });
    },
  },
};
</script>
